import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { handleRedirectToBeta } from 'common/helper';

const DashboardNotice = ({ dismissNotice, showNotice }) => {
  const closeDialog = () => dismissNotice('dashboard_notice');

  const closeAndRedirect = () => {
    closeDialog();
    handleRedirectToBeta();
  };

  return (
    <>
      <Dialog open={showNotice}>
        <DialogContent>
          <Typography align="center" variant="body1">
            <FormattedMessage
              defaultMessage={
                'Testing of the new version of Str8line is now completed, ' +
                'and the old version will be phased out on April 30, 2025. ' +
                'Enjoy easier navigation, new features, and an even better user experience. ' +
                'We recommend switching to the new interface as soon as possible for a smooth transition. ' +
                'All your data is available as before — no action is required to get started.'
              }
              id="main.PHASE_OUT_NOTIFICATION"
              values={{
                b: chunks => <strong>{chunks}</strong>
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
            size="small"
            variant="contained">
            <FormattedMessage defaultMessage="Cancel" id="common.OK" />
          </Button>
          <Button
            onClick={closeAndRedirect}
            size="small"
            style={{ color: '#fff', backgroundColor: '#007B83' }}
            variant="contained">
            <FormattedMessage
              default="Try the Beta site"
              id="main.BETA_SITE_MSG"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DashboardNotice.propTypes = {
  dismissNotice: PropTypes.func,
  showNotice: PropTypes.bool
};

export default DashboardNotice;
