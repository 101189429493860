import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteWithRoute, objectsSearch } from 'redux/object/ObjectAction';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import MentionEntry from './MentionEntry';
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils
} from 'draft-js';
import mentionRegExp from './mentionRegExp';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createMentionPlugin from '@draft-js-plugins/mention';
import './styles/mentions.css';
import './styles/staticToolbar.css';
import './styles/editor.css';
import { NoButtonPopup } from '../../../../components';
import NewObject from '../../components/NewObject/NewObject';
import {
  DIVIDER,
  MENTION,
  MENTIONED_OBJECTS,
  MENTIONED_USERS,
  OBJECT_REF,
  OBJECT,
  OBJECTS,
  OBJECT_ALL,
  OBJECTS_ALL,
  OBJECT_BOQ,
  USER
} from 'common/constants';
import {
  convertAndGetMentionedItems,
  getDisplayName,
  getEditorCurrentContent,
  getEditorState,
  getMentionedObjId
} from '../helper';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import defaultTheme from 'theme';
import { useMentions } from './hooks/UseMentions';
import { serializeParams } from 'common/helper';
import imagePlugin from './imagePlugin';
import { useWysiwygImageContext } from '../../../../context/WysiwygImageContextProvider';

const WysWyg = props => {
  const {
    agenda,
    boqId,
    clearTimer,
    defaultValue,
    discId,
    displayObjectView,
    error,
    handleMentionItems,
    handleParentEditorChange,
    ignoreExistingUserMentions,
    isReloading,
    mentionedObjs,
    mentionedUsers,
    name,
    noToolbar,
    objectTypes,
    orgName,
    projectId,
    readOnly,
    refName,
    setHasPopup,
    suggestions
  } = props;
  const dispatch = useDispatch();
  const {
    object: { objectsAll: mentionObjects }
  } = useSelector(state => state);
  const [open, setOpen] = useState(false);

  const text = defaultValue ? convertFromRaw(JSON.parse(defaultValue)) : '';
  const [editorState, setEditorState] = useState(getEditorState(text));
  const [popupState, setPopupState] = useState({ open: false });
  const [searchQuery, setSearchQuery] = useState(null);
  const [mentionSearchList, setMentionList] = useState(suggestions);
  const { mentionList, changeMentionSearch } = useMentions(mentionSearchList);

  const [newMentionKey, setNewMentionKey] = useState({
    key: null,
    added: false,
    prevKey: null
  });

  const [imageSrcList, setImageSrcList] = useState([]);
  const currentImagesRef = useRef(imageSrcList);
  const { updateImageSrc } = useWysiwygImageContext();

  useEffect(() => {
    if (!_.isEqual(currentImagesRef.current, imageSrcList)) {
      updateImageSrc(name, imageSrcList);
    }
    currentImagesRef.current = imageSrcList;
  }, [imageSrcList, name, updateImageSrc]);

  const [defaultEditorState, setDefaultEditorState] = useState(defaultValue);
  const debouncedEditorVal = useDebounce(editorState, 800);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    if (handleMentionItems) {
      const { object, user } = convertAndGetMentionedItems(
        editorState.getCurrentContent()
      );

      if (object.length > 0) {
        const editorMentionedObjects = object.map(mo => ({
          ...mo,
          ...(refName === OBJECT_REF ? { agenda_items: [agenda] } : {}),
          field: [{ name }]
        }));

        handleMentionItems(MENTIONED_OBJECTS, editorMentionedObjects, true);
      }

      if (!ignoreExistingUserMentions && user.length > 0) {
        const editorMentionedUsers = user.map(mu => ({
          ...mu,
          ...(agenda && {
            agenda_items: [agenda]
          }),
          field: [
            {
              name,
              ...(discId &&
                orgName && {
                  discipline_type_id: discId,
                  organization_name: orgName
                })
            }
          ]
        }));

        handleMentionItems(MENTIONED_USERS, editorMentionedUsers, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // ignore if initial state
    if (searchQuery != null) {
      let filteredObjects = mentionObjects?.content || [];
      if (boqId) {
        filteredObjects = filteredObjects.filter(
          obj =>
            obj.object_type.ref_name !== OBJECT_BOQ ||
            obj.metadata.boq_id === boqId
        );
      }

      const objectSuggestions = filteredObjects.reduce((acc, curr) => {
        return [
          ...acc,
          {
            id: curr.id,
            name: `${getDisplayName(curr.name)} ${curr.title}`,
            link: `/object/${curr.id}`,
            object_type: curr.object_type.ref_name,
            type: OBJECT
          }
        ];
      }, []);

      const filteredSuggestions = suggestions.reduce(
        (acc, s) => {
          if (s.type === USER) acc.users.push(s);
          else if (s.type === OBJECT && s.link.includes('/new?objectType'))
            acc.obj_types.push(s);
          return acc;
        },
        { users: [], obj_types: [] }
      );
      const updatedSuggestions = [
        ...filteredSuggestions.users,
        ...(filteredSuggestions.users.length > 0 ? [{ type: DIVIDER }] : []),
        ...objectSuggestions,
        ...filteredSuggestions.obj_types
      ];

      setMentionList(updatedSuggestions);
      changeMentionSearch(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionObjects]);

  const mentionsQuerySearch = ({ value }) => {
    setSearchQuery(value);
  };

  const processMentions = (type, data, deletedMentions) => {
    let updatedMentions = [...data];

    if (deletedMentions.length > 0) {
      deletedMentions.forEach(obj => {
        const objId = getMentionedObjId(obj);
        const idIdx = objId
          ? updatedMentions.findIndex(o => getMentionedObjId(o) === objId)
          : -1;

        if (idIdx > -1) {
          let removeItem = true;

          if (refName === OBJECT_REF) {
            const filteredAi = updatedMentions[idIdx].agenda_items.filter(
              ai =>
                !(
                  ai.rank === agenda.rank &&
                  ai.agendaItemRank === agenda.agendaItemRank
                )
            );

            if (filteredAi.length > 0) {
              removeItem = false;
              updatedMentions[idIdx].agenda_items = filteredAi;
            }
          }

          if (updatedMentions[idIdx].field?.length > 0) {
            const fieldIdx = updatedMentions[idIdx].field.findIndex(
              f => f.name === name
            );

            if (fieldIdx > -1) updatedMentions[idIdx].field.splice(fieldIdx, 1);
            if (updatedMentions[idIdx].field.length > 0) removeItem = false;
          }

          if (removeItem) {
            if (type === OBJECT && updatedMentions[idIdx].isNew)
              dispatch(deleteWithRoute(OBJECTS, objId, null, false, true));

            updatedMentions.splice(idIdx, 1);
          }
        }
      });

      handleMentionItems(type, updatedMentions);
    }
  };

  useEffect(() => {
    if (handleParentEditorChange) {
      // check if a mention has been removed
      if (!readOnly) {
        const {
          object: prevObjects,
          user: prevUsers
        } = convertAndGetMentionedItems(getEditorCurrentContent(text));
        const {
          object: currObjects,
          user: currUsers
        } = convertAndGetMentionedItems(debouncedEditorVal.getCurrentContent());

        const deletedObjects = _.differenceWith(
          prevObjects,
          currObjects,
          _.isEqual
        );
        processMentions(MENTIONED_OBJECTS, mentionedObjs, deletedObjects);

        const deletedUsers = _.differenceWith(prevUsers, currUsers, _.isEqual);
        processMentions(MENTIONED_USERS, mentionedUsers, deletedUsers);
      }

      // added this to check for empty content on wyswyg during validation
      if (refName !== OBJECT_REF)
        handleParentEditorChange(
          `${name}_text`,
          debouncedEditorVal.getCurrentContent().getPlainText()
        );
      handleParentEditorChange(
        name,
        JSON.stringify(convertToRaw(debouncedEditorVal.getCurrentContent()))
      );

      // IMAGES IN WYSWYG
      const content = convertToRaw(debouncedEditorVal.getCurrentContent());
      const srcList = [];
      Object.values(content.entityMap).forEach(entity => {
        if (entity.type === 'IMAGE' && entity.data?.src) {
          srcList.push(entity.data.src);
        }
      });
      setImageSrcList(srcList);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEditorVal]);

  useEffect(() => {
    if (projectId && null != debouncedSearchQuery) {
      dispatch(
        objectsSearch(
          serializeParams({
            project_id: projectId,
            query: debouncedSearchQuery,
            is_mentioned_object_query: true,
            type: OBJECT_ALL
          }),
          OBJECTS_ALL
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, dispatch, projectId]);

  useEffect(() => {
    if (
      (readOnly ||
        !defaultValue ||
        !defaultEditorState ||
        (defaultValue && defaultEditorState && isReloading)) &&
      defaultEditorState !== defaultValue
    ) {
      const newEditorState = text
        ? EditorState.push(editorState, text)
        : createEditorStateWithText(text);
      setEditorState(newEditorState);
      setDefaultEditorState(defaultValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEditorState, defaultValue, readOnly]);

  const formatNewItem = (type, item, isNew = false) => {
    const newItem = {
      id: item.id,
      ...(type === MENTIONED_OBJECTS
        ? { isNew, ...item, field: [{ name }] }
        : {
            field: [
              {
                name,
                ...(discId &&
                  orgName && {
                    discipline_type_id: discId,
                    organization_name: orgName
                  })
              }
            ]
          })
    };

    if (agenda) newItem.agenda_items = [agenda];

    handleMentionItems(type, [newItem], true);
  };

  const handleEditorChange = editor => {
    if (clearTimer) clearTimer();
    setEditorState(editor);
  };

  const onAddMention = obj => {
    setNewMentionKey({
      ...newMentionKey,
      key: editorState.getCurrentContent().getLastCreatedEntityKey()
    });

    if (obj.objectType)
      setPopupState({
        open: true,
        selectedObj: obj.objectType
      });
    else
      formatNewItem(
        obj.type === OBJECT ? MENTIONED_OBJECTS : MENTIONED_USERS,
        obj
      );
  };

  const handleCloseUp = () => {
    setPopupState({
      open: false,
      selectedObj: null
    });
  };

  const handleNewObject = newObjState => {
    handleCloseUp();

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    let newMentionKey;

    contentState.getBlocksAsArray().forEach(contentBlock => {
      contentBlock.findEntityRanges(
        character => {
          const entityKey = character.getEntity();
          if (entityKey !== null) {
            const entity = contentState.getEntity(entityKey);
            if (
              entity.getType() === MENTION &&
              entity.getData().mention.objectType
            ) {
              newMentionKey = entityKey;
              return true;
            }
          }
          return false;
        },
        (start, end) => {
          const name = `${newObjState.name} ${newObjState.title}`;
          contentState.replaceEntityData(newMentionKey, {
            mention: {
              id: newObjState.id,
              link: `${process.env.REACT_APP_DORADO_URL}/object/${newObjState.id}`,
              name: name,
              type: OBJECT
            }
          });
          const newContentState = Modifier.replaceText(
            contentState,
            selectionState.merge({ anchorOffset: start, focusOffset: end }),
            name,
            null,
            newMentionKey
          );

          handleEditorChange(EditorState.push(editorState, newContentState));
        }
      );
    });

    formatNewItem(MENTIONED_OBJECTS, newObjState, true);
  };

  const handleKeyCommand = command => {
    // inline formatting key commands handles bold, italic, code, underline
    const inlineEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (inlineEditorState) {
      setEditorState(inlineEditorState);
    }
  };

  const { staticToolbarPlugin, Toolbar, mentionPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionRegExp: mentionRegExp,
      mentionComponent: mentionProps => {
        let { link, name, object_type, type } = mentionProps.mention;
        if (!object_type) object_type = name.split('-')[0];

        return (
          <span
            className={mentionProps.className}
            // eslint-disable-next-line no-alert
            onClick={() =>
              (!type || type === OBJECT) && // older OBJECT mentions do not have the type specified
              displayObjectView(link.split('/object/')[1], object_type)
            }>
            {mentionProps.children}
          </span>
        );
      }
    });
    const staticToolbarPlugin = createToolbarPlugin();
    const { Toolbar } = staticToolbarPlugin;

    return {
      staticToolbarPlugin,
      Toolbar,
      mentionPlugin
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editor = useRef({ current: null });
  const { MentionSuggestions } = mentionPlugin;
  let plugins = [mentionPlugin, ...imagePlugin];

  let toolBar;
  if (!readOnly && !noToolbar) {
    toolBar = <Toolbar />;
    plugins.push(staticToolbarPlugin);
  }

  return (
    <div
      className="wyswyg-container-dfgewertg"
      onClick={() => editor.current && editor.current.focus()}
      style={{
        borderColor: error ? '#e53935' : '#ddd',
        fontFamily: defaultTheme.typography.fontFamily
      }}>
      {toolBar}
      <Editor
        editorKey={'editor'}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        onChange={handleEditorChange}
        plugins={plugins}
        readOnly={readOnly || popupState.open}
        ref={e => (editor.current = e)}
        spellCheck={!readOnly}
      />
      <MentionSuggestions
        entryComponent={MentionEntry}
        onAddMention={onAddMention}
        onOpenChange={setOpen}
        onSearchChange={mentionsQuerySearch}
        open={open}
        suggestions={mentionList}
      />
      <NoButtonPopup
        handleParentClose={handleCloseUp}
        setHasPopup={setHasPopup}
        show={popupState.open}>
        <NewObject
          handleParentFormState={handleNewObject}
          objectTypes={objectTypes}
          projectId={projectId}
          selectedObjType={popupState.selectedObj}
        />
      </NoButtonPopup>
    </div>
  );
};

WysWyg.propTypes = {
  agenda: PropTypes.object,
  boqId: PropTypes.string,
  clearTimer: PropTypes.func,
  defaultValue: PropTypes.string,
  discId: PropTypes.string,
  displayObjectView: PropTypes.func,
  error: PropTypes.bool,
  handleMentionItems: PropTypes.func,
  handleParentEditorChange: PropTypes.func,
  handleParentUserMentionChange: PropTypes.func,
  ignoreExistingUserMentions: PropTypes.bool,
  isReloading: PropTypes.bool,
  mentionedObjs: PropTypes.array,
  mentionedUsers: PropTypes.array,
  name: PropTypes.string.isRequired,
  noToolbar: PropTypes.bool,
  objectTypes: PropTypes.array,
  orgName: PropTypes.string,
  projectId: PropTypes.string,
  readOnly: PropTypes.bool,
  refName: PropTypes.string,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

WysWyg.defaultProps = {
  discId: null,
  ignoreExistingUserMentions: false,
  mentionedObjs: [],
  mentionedUsers: [],
  orgName: null,
  suggestions: []
};

export default WysWyg;
