import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { handleRedirectToBeta } from 'common/helper';

const NewDashboard = () => {
  return (
    <div align="center">
      <Box
        alignItems="center"
        display="block"
        justifyItems="center"
        marginTop={6}>
        <Typography align="center" variant="body1">
          <FormattedMessage
            defaultMessage={
              'Testing of the new version of Str8line is now completed, ' +
              'and the old version will be phased out on April 30, 2025. ' +
              'Enjoy easier navigation, new features, and an even better user experience. ' +
              'We recommend switching to the new interface as soon as possible for a smooth transition. ' +
              'All your data is available as before — no action is required to get started.'
            }
            id="main.PHASE_OUT_NOTIFICATION"
            values={{
              b: chunks => <strong>{chunks}</strong>
            }}
          />
        </Typography>
      </Box>
      <Button
        fullWidth
        onClick={handleRedirectToBeta}
        size="small"
        style={{ color: '#fff', backgroundColor: '#007B83', marginTop: 16 }}
        variant="contained">
        <FormattedMessage
          defaultMessage="Log In To New Version"
          id="main.LOGIN_TO_ORION"
        />
      </Button>
    </div>
  );
};

NewDashboard.propTypes = {
  updateSettings: PropTypes.func
};

export default NewDashboard;
