import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { WysWyg } from '../../components';
import { Clear as ClearIcon, Event as EventIcon } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { formatDate } from 'common/helper';
import { formatForEditor } from '../../components/helper';
import { overrideProps, useStyles } from '../../styles';
import {
  DEFAULT_DATE_FORMAT,
  DUE_DATE,
  DUE_DATE_MIN,
  LANGUAGE_NB,
  LS_LANGUAGE,
  OBJECT_BHM,
  OBJECT_DOC,
  REVISION_VALUES,
  ROLE_DL,
  ROLE_PL,
  STATUS_DRAFT,
  STATUS_READY_FOR_ISSUE,
  STATUS_RESPONSE_ISSUED,
  VALUES
} from 'common/constants';

const Ta = props => {
  const {
    clearTimer,
    createdBy,
    disableEdit,
    displayObjectView,
    formState,
    handleEditorChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    isReloading,
    objectTypes,
    organizations,
    projectId,
    refName,
    setFormState,
    setHasPopup,
    suggestions
  } = props;
  const classes = useStyles();

  const {
    object: { disciplineTypes },
    project: { projectSelected }
  } = useSelector(state => state);
  const [dateField, setDateField] = useState({ focused: false, open: false });
  const [actionOwners, setActionOwners] = useState([]);

  const {
    errors,
    isRevision,
    mentionedObjs,
    mentionedUsers,
    selectedRevision
  } = formState;

  const valuesField = useMemo(() => (isRevision ? REVISION_VALUES : VALUES), [
    isRevision
  ]);
  const {
    action_owner,
    discipline_type_id: recipient_organization_id,
    id,
    metadata: { due_date, inquiry, reference, response, solutions },
    status
  } = formState[valuesField];

  const statusName = useMemo(() => status?.name || STATUS_DRAFT, [status]);
  const initState = useMemo(
    () => [STATUS_DRAFT, STATUS_READY_FOR_ISSUE].includes(statusName),
    [statusName]
  );
  const isSenderEdit = useMemo(
    () => initState || (statusName === STATUS_RESPONSE_ISSUED && isCreatorOrg),
    [initState, isCreatorOrg, statusName]
  );

  const showResponse = useMemo(() => !(initState && selectedRevision <= 0), [
    initState,
    selectedRevision
  ]);

  const recipientActionOwner = useMemo(
    () => action_owner?.recipient_action_owner,
    [action_owner]
  );

  const selectedDiscipline = useMemo(() => action_owner?.discipline_type, [
    action_owner
  ]);

  const recipientOrg = useMemo(() => {
    return organizations?.find(org => org.id === recipient_organization_id);
  }, [organizations, recipient_organization_id]);

  const disciplines = useMemo(() => {
    if (!recipientOrg?.name) return [];
    return disciplineTypes[recipientOrg.name] ?? [];
  }, [disciplineTypes, recipientOrg]);

  const handleDateSelection = useCallback(
    value => {
      setDateField(df => ({ ...df, focused: value, open: value }));
      setHasPopup(value);
    },
    [setHasPopup]
  );

  const handleDateChange = useCallback(
    date => {
      const due_date =
        !date || isNaN(date)
          ? date && date.target
            ? date.target.value
            : ''
          : formatDate(date, DEFAULT_DATE_FORMAT);

      setFormState(formState => ({
        ...formState,
        [valuesField]: {
          ...formState[valuesField],
          metadata: {
            ...formState[valuesField].metadata,
            due_date,
            due_date_min: due_date
          }
        }
      }));
    },
    [setFormState, valuesField]
  );

  const handleDisciplineTypeChange = useCallback(
    (_, value) => {
      setFormState(formState => {
        return {
          ...formState,
          [valuesField]: {
            ...formState[valuesField],
            action_owner: {
              ...formState[valuesField].action_owner,
              discipline_type_id: value?.id || null,
              discipline_type: value,
              recipient_action_owner_id: null,
              recipient_action_owner: null
            }
          }
        };
      });
    },
    [setFormState, valuesField]
  );

  const handleActionOwnerChange = useCallback(
    (_, value) => {
      setFormState(formState => ({
        ...formState,
        [valuesField]: {
          ...formState[valuesField],
          action_owner: {
            ...formState[valuesField].action_owner,
            recipient_action_owner_id: value.id,
            recipient_action_owner: {
              id: value.id,
              email: value.email,
              name: value.name
            }
          }
        }
      }));
    },
    [setFormState, valuesField]
  );

  useEffect(() => {
    const recipient_discipline_type_id = selectedDiscipline?.id;
    // all org users that belong to the project and selected discipline
    const orgProjUsers =
      recipient_discipline_type_id && recipientOrg?.users.length > 0
        ? recipientOrg.users.filter(user =>
            user.project_settings.some(
              ps =>
                ps.role.name === ROLE_PL ||
                ps.discipline_type?.id === recipient_discipline_type_id
            )
          )
        : [];

    let actionOwner;
    if (recipientActionOwner) {
      actionOwner = recipientActionOwner;
    } else if (recipient_discipline_type_id) {
      actionOwner = orgProjUsers.find(user =>
        user.project_settings.some(
          ps =>
            ps.role.name === ROLE_DL &&
            ps.discipline_type?.id === recipient_discipline_type_id
        )
      );
      if (!actionOwner)
        // if dl is not found, set PL as action owner
        actionOwner = orgProjUsers.find(user =>
          user.project_settings.some(ps => ps.role.name === ROLE_PL)
        );
    }

    setActionOwners(orgProjUsers);
    setFormState(formState => {
      const ao_metadata = actionOwner
        ? {
            recipient_action_owner_id: actionOwner.id,
            recipient_action_owner: {
              id: actionOwner.id,
              email: actionOwner.email,
              name: actionOwner.name
            }
          }
        : {};

      return {
        ...formState,
        [valuesField]: {
          ...formState[valuesField],
          action_owner: {
            ...formState[valuesField].action_owner,
            ...ao_metadata
          }
        }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientOrg, selectedDiscipline]);

  const showReference = useMemo(
    () => !!projectSelected?.settings?.bhm_ta_reference_field,
    [projectSelected]
  );

  return (
    <div>
      <Divider />
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={4}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Created by"
                  id="common.CREATED_BY"
                />
              }
              size="small"
              value={createdBy || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <FormControl
              disabled={!initState || disableEdit}
              fullWidth
              variant="outlined">
              <InputLabel
                className={classNames(
                  classes.dateLabel,
                  hasError(DUE_DATE) ? classes.dateLabelError : ''
                )}
                htmlFor="outlined-adornment-date">
                <FormattedMessage
                  defaultMessage="Answer Deadline"
                  id="object.ANSWER_DEADLINE"
                />
              </InputLabel>
              <OutlinedInput
                className={classes.whiteBackground}
                disabled={!initState || disableEdit}
                endAdornment={
                  <InputAdornment position="end">
                    <>
                      {dateField.focused &&
                      due_date &&
                      initState &&
                      !disableEdit ? (
                        <IconButton
                          className={classes.grayIcon}
                          disabled={disableEdit}
                          edge="end"
                          onClick={handleDateChange}
                          size="small">
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {!initState || disableEdit ? null : (
                        <IconButton
                          className={classes.grayIcon}
                          edge="end"
                          onClick={() => handleDateSelection(true)}
                          size="small">
                          <EventIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  </InputAdornment>
                }
                error={hasError(DUE_DATE) || hasError(DUE_DATE_MIN)}
                id="outlined-adornment-date"
                inputProps={{ style: overrideProps.content }}
                labelWidth={
                  localStorage.getItem(LS_LANGUAGE) === LANGUAGE_NB ? 100 : 110
                }
                onBlur={() => setDateField(df => ({ ...df, focused: false }))}
                onChange={handleDateChange}
                onFocus={() => setDateField(df => ({ ...df, focused: true }))}
                onMouseEnter={() =>
                  setDateField(df => ({ ...df, focused: true }))
                }
                onMouseLeave={() =>
                  setDateField(df => ({ ...df, focused: false }))
                }
                value={due_date || ''}
              />
              <FormHelperText error id="dueDateHelperText">
                {hasError(DUE_DATE)
                  ? formState.errors.due_date[0]
                  : hasError(DUE_DATE_MIN)
                  ? formState.errors.due_date_min[0]
                  : null}
              </FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                clearable
                disablePast
                onAccept={() => handleDateSelection(false)}
                onChange={handleDateChange}
                onClose={() => handleDateSelection(false)}
                open={dateField.open}
                TextFieldComponent={() => null}
                value={due_date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={3} xs={4}>
            <Autocomplete
              className={classes.whiteBackground}
              disabled={(isSenderEdit && !initState) || disableEdit}
              filterSelectedOptions
              getOptionLabel={option => option.name || ''}
              name="discipline_type_id"
              onChange={(event, value) =>
                handleDisciplineTypeChange(event, value)
              }
              options={disciplines}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Select Discipline Type"
                      id="object.SELECT_DISCIPLINE_TYPE"
                    />
                  }
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={selectedDiscipline || null}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <Autocomplete
              className={classes.whiteBackground}
              disableClearable
              disabled={isSenderEdit || disableEdit}
              filterSelectedOptions
              getOptionLabel={option => option.name ?? option.email ?? ''}
              onChange={(event, value) => handleActionOwnerChange(event, value)}
              options={actionOwners}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Action Owner"
                      id="object.ACTION_OWNER"
                    />
                  }
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={recipientActionOwner || null}
            />
          </Grid>
          {showReference ? (
            <Grid item xs={12}>
              <>
                <Typography className={classes.boldLabel}>
                  <FormattedMessage
                    defaultMessage="Reference (drawing number, etc.)"
                    id="object.REFERENCE2"
                  />
                  :
                </Typography>
                <WysWyg
                  clearTimer={clearTimer}
                  defaultValue={formatForEditor(reference) || ''}
                  displayObjectView={displayObjectView}
                  handleMentionItems={handleMentionItems}
                  handleParentEditorChange={handleEditorChange}
                  isReloading={isReloading}
                  key={`referene_${id}`}
                  mentionedObjs={mentionedObjs}
                  mentionedUsers={mentionedUsers}
                  name="reference"
                  noToolbar
                  objectTypes={objectTypes}
                  projectId={projectId}
                  readOnly={!initState || disableEdit}
                  setHasPopup={setHasPopup}
                  suggestions={suggestions.filter(
                    s => s.object_type && s.object_type === OBJECT_DOC
                  )}
                />
              </>
            </Grid>
          ) : null}
          <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
            <Typography className={classes.boldLabel}>
              <FormattedMessage defaultMessage="Query" id="object.QUERY" />: *
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <WysWyg
              clearTimer={clearTimer}
              defaultValue={inquiry}
              displayObjectView={displayObjectView}
              error={hasError('inquiry_text')}
              handleMentionItems={handleMentionItems}
              handleParentEditorChange={handleEditorChange}
              isReloading={isReloading}
              key={`inquiry_${id}`}
              mentionedObjs={mentionedObjs}
              mentionedUsers={mentionedUsers}
              name="inquiry"
              objectTypes={objectTypes}
              projectId={projectId}
              readOnly={!isSenderEdit || disableEdit}
              setHasPopup={setHasPopup}
              suggestions={suggestions}
            />
            {hasError('inquiry_text') ? (
              <FormHelperText error id="inquiryHelperText">
                {errors.inquiry_text[0]}
              </FormHelperText>
            ) : null}
          </Grid>
          {refName === OBJECT_BHM ? null : (
            <>
              <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
                <Typography className={classes.boldLabel}>
                  <FormattedMessage
                    defaultMessage="Possible Solutions"
                    id="object.POSSIBLE_SOLUTIONS"
                  />
                  : *
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <WysWyg
                  clearTimer={clearTimer}
                  defaultValue={solutions}
                  displayObjectView={displayObjectView}
                  error={hasError('solutions_text')}
                  handleMentionItems={handleMentionItems}
                  handleParentEditorChange={handleEditorChange}
                  isReloading={isReloading}
                  key={`solutions_${id}`}
                  mentionedObjs={mentionedObjs}
                  mentionedUsers={mentionedUsers}
                  name="solutions"
                  objectTypes={objectTypes}
                  projectId={projectId}
                  readOnly={!isSenderEdit || disableEdit}
                  setHasPopup={setHasPopup}
                  suggestions={suggestions}
                />
                {hasError('solutions_text') ? (
                  <FormHelperText error id="solutionsHelperText">
                    {errors.solutions_text[0]}
                  </FormHelperText>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider className={classes.dividerMargin} />
      {showResponse ? (
        <CardContent className={classes.grayContent}>
          <Grid container spacing={2}>
            <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
              <Typography className={classes.boldLabel}>
                <FormattedMessage
                  defaultMessage="Response"
                  id="object.RESPONSE"
                />
                : *
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={response}
                displayObjectView={displayObjectView}
                error={hasError('response_text')}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                isReloading={isReloading}
                key={`response_${id}`}
                mentionedObjs={mentionedObjs}
                mentionedUsers={mentionedUsers}
                name="response"
                objectTypes={objectTypes}
                projectId={projectId}
                readOnly={isSenderEdit || disableEdit}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
              {hasError('response_text') ? (
                <FormHelperText error id="responseHelperText">
                  {errors.response_text[0]}
                </FormHelperText>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      ) : null}
    </div>
  );
};

Ta.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isForward: PropTypes.bool,
  isReloading: PropTypes.bool,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  refName: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Ta;
