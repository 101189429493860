import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import PropTypes from 'prop-types';

const WysiwygImageContext = createContext(null);

function WysiwygImageContextProvider({ children }) {
  const [imageSrcList, setImageSrcList] = useState([]);

  const updateImageSrc = useCallback((field, list) => {
    setImageSrcList(prev => [
      ...prev.filter(item => item.field !== field),
      ...list.map(src => ({ src, field }))
    ]);
  }, []);

  const value = useMemo(
    () => ({
      imageSrcList,
      updateImageSrc
    }),
    [imageSrcList, updateImageSrc]
  );

  return (
    <WysiwygImageContext.Provider value={value}>
      {children}
    </WysiwygImageContext.Provider>
  );
}

WysiwygImageContextProvider.propTypes = {
  children: PropTypes.node
};

export default WysiwygImageContextProvider;

export function useWysiwygImageContext() {
  const context = useContext(WysiwygImageContext);
  if (!context) {
    throw new Error(
      'useWysiwygImageContext must be used within a WysiwygImageContextProvider'
    );
  }
  return context;
}
