import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { login, otp } from '../../redux';
import { setupOrg } from 'common/helper';
import { emailSchema } from 'common/schema';
import { LS_STR8LINE_TOKEN, SS_STORED_PROJECT_ID } from 'common/constants';
import LoginAlternatives from '../../components/LoginAlternatives';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import NewDashboard from './NewDashboard';

const loginSchema = {
  ...emailSchema,
  password: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Password is required"
          id="error.REQUIRED_PASSWORD"
        />
      )
    },
    length: {
      maximum: 128
    }
  }
};

const otpSchema = {
  code: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="One time password is required"
          id="error.REQUIRED_OTP"
        />
      )
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    paddingTop: theme.spacing(7),
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  authLink: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

const SignIn = props => {
  const classes = useStyles();
  const {
    location: { message, search },
    history
  } = props;
  const query = QueryString.parse(search);
  const {
    user: {
      error: userError,
      info: { id, organizations },
      loading: userLoading,
      showOtp
    }
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    propError: message,
    showPassword: false
  });
  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);
  const error = formState.propError || userError;
  const errorMsg = error && typeof error === 'object' ? error.message : error;
  const token = localStorage.getItem(LS_STR8LINE_TOKEN);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (showOtp) dispatch(otp({ ...formState.values, id }));
    else {
      dispatch(login(formState.values));
    }
  };

  const handleClickShowPassword = () =>
    setFormState(state => ({ ...state, showPassword: !state.showPassword }));

  useEffect(() => {
    const errors = validate(
      formState.values,
      showOtp ? otpSchema : loginSchema
    );

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    if (id && token) {
      setupOrg(organizations && organizations[0]);

      if (query.project_id) {
        if (query.object_id)
          history.push(
            `/object/${query.object_id}?project_id=${query.project_id}`
          );
        else {
          sessionStorage.setItem(SS_STORED_PROJECT_ID, query.project_id);
          history.push('/objects');
        }
      } else {
        history.push('/projects');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, token]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Paper className={classes.contentPaper} elevation={4}>
              {errorMsg ? (
                <Alert className={classes.alert} severity="error">
                  <FormattedMessage
                    defaultMessage={
                      <FormattedMessage
                        defaultMessage="Unexpected server response."
                        id="error.UNEXPECTED_RESPONSE"
                      />
                    }
                    id={errorMsg}
                  />
                </Alert>
              ) : showOtp ? (
                <Alert className={classes.alert} severity="info">
                  <FormattedMessage
                    defaultMessage={
                      <FormattedMessage
                        defaultMessage="Please check your email for the one time password."
                        id="main.OTP_INFO_MESSAGE"
                      />
                    }
                    id="main.OTP_INFO_MESSAGE"
                  />
                </Alert>
              ) : null}
              <Typography variant="h2">
                <FormattedMessage defaultMessage="Sign in" id="main.SIGN_IN" />
              </Typography>
              {showOtp ? (
                <>
                  <TextField
                    className={classes.textField}
                    error={hasError('code')}
                    fullWidth
                    helperText={
                      hasError('code') ? formState.errors.code[0] : null
                    }
                    id="code"
                    label={
                      <FormattedMessage
                        defaultMessage="One Time Password"
                        id="common.OTP"
                      />
                    }
                    name="code"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.code || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || userLoading}
                    fullWidth
                    id="verify"
                    size="large"
                    type="submit"
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Verify"
                      id="main.VERIFY_OTP"
                    />
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                    id="email"
                    label={
                      <FormattedMessage
                        defaultMessage="Email address"
                        id="common.EMAIL_ADDRESS"
                      />
                    }
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    id="password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}
                            onMouseDown={event => event.preventDefault()}
                            onMouseUp={event => event.preventDefault()}>
                            {formState.showPassword ? (
                              <Tooltip
                                title={
                                  <FormattedMessage
                                    defaultMessage="Click to hide the password"
                                    id="main.HIDE_PASSWORD"
                                  />
                                }>
                                <VisibilityOff fontSize="small" />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <FormattedMessage
                                    defaultMessage="Click to show the password"
                                    id="main.SHOW_PASSWORD"
                                  />
                                }>
                                <Visibility fontSize="small" />
                              </Tooltip>
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    label={
                      <FormattedMessage
                        defaultMessage="Password"
                        id="main.PASSWORD"
                      />
                    }
                    name="password"
                    onChange={handleChange}
                    type={formState.showPassword ? 'text' : 'password'}
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || userLoading}
                    fullWidth
                    id="signin"
                    size="large"
                    type="submit"
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Sign in"
                      id="main.SIGN_IN"
                    />
                  </Button>
                  <div className={classes.authLink}>
                    <Typography color="textSecondary" variant="body1">
                      <Link component={RouterLink} to="/forgot" variant="h6">
                        <FormattedMessage
                          defaultMessage="Forgot password?"
                          id="main.FORGOT_PASSWORD"
                        />
                      </Link>
                    </Typography>
                  </div>
                </>
              )}
              <LoginAlternatives />
              <NewDashboard />
            </Paper>
          </form>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(SignIn);
