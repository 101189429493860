/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import { composeDecorators } from '@draft-js-plugins/editor';

import { FormattedMessage } from 'react-intl';

import { getPresignedUrl } from 'common/ursa';
import { ACTION_GET, BUCKET_ATTACHMENTS } from 'common/constants';

import { Box } from '@material-ui/core';

import clsx from 'clsx';
import React, { forwardRef, useMemo, useState, useEffect } from 'react';

const CustomImageComponent = forwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function Image(props, ref) {
    const { block, className, theme = {}, ...otherProps } = props;
    // leveraging destructuring to omit certain properties from props
    const {
      blockProps,
      customStyleMap,
      customStyleFn,
      decorator,
      forceSelection,
      offsetKey,
      selection,
      tree,
      blockStyleFn,
      preventScroll,
      contentState,
      ...elementProps
    } = otherProps;
    const combinedClassName = useMemo(() => clsx(theme.image, className), [
      className,
      theme.image
    ]);

    const { src } = contentState.getEntity(block.getEntityAt(0)).getData();

    const [signedUrl, setSignedUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const url = new URL(src);
      setIsLoading(true);
      getPresignedUrl(ACTION_GET, url.pathname, BUCKET_ATTACHMENTS)
        .then(data => {
          setSignedUrl(data.signedUrl);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [src]);

    if (isLoading) {
      return (
        <span>
          [
          <FormattedMessage
            defaultMessage="Loading image"
            id="common.LOADING_IMAGE"
          />
          ]
        </span>
      );
    }

    if (!signedUrl) {
      return (
        <span>
          [
          <FormattedMessage
            defaultMessage="Failed to load image"
            id="common.FAILED_TO_LOAD_IMAGE"
          />
          ]
        </span>
      );
    }

    return (
      <Box
        component="img"
        {...elementProps}
        alt={elementProps.alt || 'attachment'}
        className={combinedClassName}
        /* disable actual resize of images */
        onMouseDown={e => e.preventDefault()}
        onMouseLeave={e => e.preventDefault()}
        onMouseMove={e => e.preventDefault()}
        ref={ref}
        src={signedUrl}
      />
    );
  }
);

const resizeablePlugin = createResizeablePlugin({
  initialWidth: 'min(500px, 100%)'
});

const imagePlugin = createImagePlugin({
  imageComponent: CustomImageComponent,
  decorator: composeDecorators(resizeablePlugin.decorator)
});

export default [resizeablePlugin, imagePlugin];
