import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@material-ui/core';
import { ObjectPreview, ObjectView } from '../../../Object';
import { useStyles } from '../../styles';
import { OBJECT, OBJECT_DOC, OBJECT_TMPL } from 'common/constants';
import { getDisplayName, getObjectTypeByRefName, isDocObject } from '../helper';
import _ from 'lodash';

const DocumentFlow = props => {
  const classes = useStyles();
  const {
    displayObjectView,
    hasAttachment,
    history,
    isBoQ,
    isCompletedStatus,
    isDoc,
    isFormUpdated,
    isProjectArchived,
    isUnshared,
    objectId,
    objects,
    organizations,
    projectId,
    revision,
    setConfirm,
    setFormState,
    state,
    topAccessLevel,
    types
  } = props;
  const execObjPreview = objectId && !state.objectId;
  const showObjPreview = execObjPreview || isDocObject(state.objectType);
  const goToObjectInfo = (id, category = OBJECT) => {
    if (isFormUpdated() && !isCompletedStatus)
      setConfirm({
        onConfirm: () => {
          handlePageChange(id, category);
        }
      });
    else handlePageChange(id, category);
  };

  const handlePageChange = (id, category) => {
    setConfirm(null);
    setFormState(formState => ({
      ...formState,
      revisionChanged: false
    }));
    history.push(`/${category}/${id}?project_id=${projectId}`, {
      isDoc: category === OBJECT_DOC.toLowerCase(),
      projectId: projectId
    });
  };

  const groupedObjects = Object.values(
    _.groupBy(objects, 'name')
  ).map(objectGroup =>
    objectGroup
      .sort((a, b) => a.metadata?.revision - b.metadata?.revision)
      .at(-1)
  );

  const sortedObjects = groupedObjects.sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );

  return (
    <Card className={classes.docflowRoot}>
      <CardHeader
        className={classes.docflowHeader}
        title={
          <FormattedMessage
            defaultMessage="Document Flow"
            id="common.DOCUMENT_FLOW"
          />
        }
      />
      <CardContent className={classes.docFlowContent}>
        {isDoc && objects.length === 1 ? null : (
          <Box
            className={classes.docflowListContainer}
            display="flex"
            flexDirection="column">
            {sortedObjects.map(o => {
              const buttonClassName =
                o.id === objectId
                  ? classNames(
                      classes.docflowButton,
                      classes.backgroundDarkBlue
                    )
                  : classes.docflowButton;

              return o.object_type === OBJECT_TMPL ? null : (
                <Button
                  className={buttonClassName}
                  color="secondary"
                  key={o.id}
                  onClick={() =>
                    displayObjectView(
                      o.id,
                      o.object_type
                        ? getObjectTypeByRefName(types, o.object_type).name
                        : o.objectType
                    )
                  }
                  variant="contained">
                  <Typography
                    className={classes.docflowButtonName}
                    variant="button">
                    {getDisplayName(o.name) || ''}:
                  </Typography>
                  <br />
                  <Typography
                    className={classes.docflowButtonTitle}
                    variant="button">
                    {o.title || ''}
                  </Typography>
                </Button>
              );
            })}
          </Box>
        )}
        {!state.objectId &&
        (!objectId || !hasAttachment) &&
        !isUnshared ? null : showObjPreview ? (
          <ObjectPreview
            className={
              isDoc && objects.length === 1 ? null : classes.docflowObjView
            }
            goToObjectInfo={goToObjectInfo}
            hideOpen={objectId === state.objectId || execObjPreview}
            isBoQ={isBoQ}
            isDocFlow
            isProjectArchived={isProjectArchived}
            objectId={state.objectId || objectId}
            projectId={projectId}
            revision={revision}
            selectedAttachment={state.index}
            topAccessLevel={topAccessLevel}
          />
        ) : (
          <ObjectView
            className={classes.docflowObjView}
            displayObjectView={displayObjectView}
            goToObjectInfo={goToObjectInfo}
            isProjectArchived={isProjectArchived}
            objectId={state.objectId}
            organizations={organizations}
          />
        )}
      </CardContent>
    </Card>
  );
};

DocumentFlow.propTypes = {
  displayObjectView: PropTypes.func,
  hasAttachment: PropTypes.bool,
  history: PropTypes.object,
  isBoQ: PropTypes.bool,
  isCompletedStatus: PropTypes.bool,
  isDoc: PropTypes.bool,
  isFormUpdated: PropTypes.func,
  isProjectArchived: PropTypes.bool,
  isUnshared: PropTypes.bool,
  objectId: PropTypes.string,
  objects: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  revision: PropTypes.object,
  setConfirm: PropTypes.func,
  setFormState: PropTypes.func,
  state: PropTypes.object,
  topAccessLevel: PropTypes.string,
  types: PropTypes.array
};

export default DocumentFlow;
