import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useStyles } from '../../../styles';
import { ObjectsPagination } from 'components';
import {
  OBJECT_COLUMN_ID_ACTION_OWNER,
  OBJECT_COLUMN_ID_FIRST_PUBLISHED,
  OBJECT_COLUMN_ID_LAST_UPDATED,
  OBJECT_COLUMN_ID_NAME,
  OBJECT_COLUMN_ID_STATUS,
  OBJECT_COLUMN_ID_TITLE,
  OBJECT_LIST_LIMIT,
  SORT_ORDER_ASC
} from 'common/constants';

const ExecutionTable = props => {
  const {
    displayObjectView,
    goToObjectInfo,
    handleChangePage,
    isMobileDevice,
    isProjectArchived,
    object: { loading: objectLoading, objects },
    objectId,
    order,
    orderBy,
    projectLoading,
    setTableSort: setSort
  } = props;
  const classes = useStyles();
  const [states, setStates] = useState({ content: [], page: 0, total: 0 });
  const { content, page, total } = states;

  useEffect(() => {
    const { content = [], page = 0, total = 0 } = objects;
    setStates(states => ({
      ...states,
      content,
      page,
      total
    }));
  }, [objects]);

  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          className={classes.table}
          id="execution-object-table"
          name="execution-object-table"
          size="small"
          stickyHeader>
          <colgroup>
            <col width="20%" />
            <col width="30%" />
            <col width="15%" />
            <col width="10%" />
            <col width="10%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_NAME}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_NAME ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_NAME)}>
                  <FormattedMessage
                    defaultMessage="Object"
                    id="common.OBJECT"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_TITLE}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_TITLE ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_TITLE)}>
                  <FormattedMessage defaultMessage="Title" id="common.TITLE" />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_STATUS}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_STATUS ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_STATUS)}>
                  <FormattedMessage
                    defaultMessage="Status"
                    id="common.STATUS"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_FIRST_PUBLISHED}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_FIRST_PUBLISHED
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_FIRST_PUBLISHED)}>
                  <FormattedMessage
                    defaultMessage="First published"
                    id="object.FIRST_PUBLISHED"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_LAST_UPDATED}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_LAST_UPDATED
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_LAST_UPDATED)}>
                  <FormattedMessage
                    defaultMessage="Last updated"
                    id="object.LAST_UPDATED"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" size="small">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_ACTION_OWNER}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_ACTION_OWNER
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_ACTION_OWNER)}>
                  <FormattedMessage
                    defaultMessage="Action Owner"
                    id="object.ACTION_OWNER"
                  />
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!objectLoading && !projectLoading
              ? content.map((obj, index) => {
                  return (
                    <TableRow
                      hover
                      id={`object-row-${index}`}
                      key={obj.id}
                      onClick={() =>
                        displayObjectView(obj.id, obj.object_type.name)
                      }
                      selected={obj.id === objectId}>
                      <TableCell align="left">
                        {isMobileDevice || isProjectArchived ? (
                          obj.name
                        ) : (
                          <Tooltip
                            arrow
                            title={
                              <FormattedMessage
                                defaultMessage="Open"
                                id="object.OPEN"
                              />
                            }>
                            <span
                              className={classes.inlineFlex}
                              onClick={() => goToObjectInfo(obj.id)}>
                              <Typography
                                className={classes.link}
                                color="secondary"
                                variant="body1">
                                {obj.name}
                              </Typography>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">{obj.title}</TableCell>
                      <TableCell align="left">{obj.display_status}</TableCell>
                      <TableCell>{obj.first_published}</TableCell>
                      <TableCell>{obj.last_updated}</TableCell>
                      <TableCell align="left" size="small">
                        {obj.organization_action_owner}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <ObjectsPagination
        handleChangePage={handleChangePage}
        limit={OBJECT_LIST_LIMIT}
        page={page}
        total={total}
      />
    </>
  );
};

ExecutionTable.propTypes = {
  displayObjectView: PropTypes.func.isRequired,
  goToObjectInfo: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  object: PropTypes.object,
  objectId: PropTypes.string,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  projectLoading: PropTypes.bool,
  setTableSort: PropTypes.func
};

export default ExecutionTable;
