import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Confirm, Popup, Uploader } from 'components';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  GetApp as DownloadIcon,
  Refresh as UploadIcon,
  Save as SaveIcon,
  Info
} from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import { actionOnS3File } from 'common/ursa';
import { deleteWithRoute, updateWithRoute } from 'redux/object/ObjectAction';
import {
  downloadS3File,
  formatDate,
  getFilename,
  getFilenameWithoutExt,
  getFilenameWithoutID,
  isCurrentOrg,
  splitFileUrl
} from 'common/helper';
import { getUploadPath, processDownload } from '../../components/helper';
import { useStyles } from '../../styles';
import {
  ACTION_DELETE,
  ACTION_PUT,
  ATTACHMENT_DATE_FORMAT,
  ATTACHMENTS,
  MAX_FILE_COUNT,
  REGEX_LAST_DOT,
  REVISION_VALUES,
  STATUS_DRAFT,
  VALUES
} from 'common/constants';
import { useWysiwygImageContext } from 'context/WysiwygImageContextProvider';

const Attachment = props => {
  const {
    formState,
    handleAttachmentClick,
    hasError,
    isEditable,
    isView,
    orgId,
    projectId,
    setFormState,
    setHasPopup
  } = props;
  const classes = useStyles();
  const { object } = useSelector(state => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [download, setDownload] = useState({ file: '', message: '' });
  const { imageSrcList } = useWysiwygImageContext();

  const fsValuesField = formState.isRevision ? REVISION_VALUES : VALUES;
  const objectId = formState[fsValuesField].id
    ? formState[fsValuesField].id
    : '';
  const isRevision =
    !isView &&
    (formState.isRevision ||
      (formState[fsValuesField].metadata &&
        formState[fsValuesField].metadata.revision > 0 &&
        (!formState[fsValuesField].status ||
          formState[fsValuesField].status.name === STATUS_DRAFT)));

  const revLen = object.infoRevisions.length;

  const prevRevAttachments = formState.isRevision
    ? formState.values.attachments
    : revLen > 1
    ? object.infoRevisions[revLen - 2].attachments
    : [];
  const isPrevRevFile = id => prevRevAttachments.some(a => a.id === id);

  const setAttachments = attachments => {
    setFormState(formState => ({
      ...formState,
      [fsValuesField]: {
        ...formState[fsValuesField],
        attachments: [...attachments]
      }
    }));
  };

  const addAttachment = newData => {
    let rank = newData.rank;
    const newAttachments = Object.values(newData.files).reduce((acc, v) => {
      if (v.error) return acc;
      return acc.concat({
        rank: rank++,
        file: v.file,
        uploaded_by_org_id: orgId
      });
    }, []);
    newAttachments.reverse();
    const attachments = formState[fsValuesField].attachments
      ? [...newAttachments, ...formState[fsValuesField].attachments]
      : [...newAttachments];
    setAttachments(attachments);
  };

  const replaceAttachment = newData => {
    const attachments = formState[fsValuesField].attachments;
    const reviseIndex = attachments.findIndex(a => a.rank === newData.rank);

    if (objectId)
      setFormState(formState => ({
        ...formState,
        deletedAttachments: formState.deletedAttachments.concat(
          attachments[reviseIndex].id
        )
      }));

    delete attachments[reviseIndex].id;
    attachments[reviseIndex].file = Object.values(newData.files)[0].file;
    setAttachments(attachments);
  };

  const handleChange = (event, index) => {
    const attachments = formState[fsValuesField].attachments;
    const selected = attachments[index];
    const [path, file] = splitFileUrl(selected.file);
    const [file_id, completeFile] = getFilename(file);
    const [, ext] = completeFile.split(REGEX_LAST_DOT);

    if (!selected.old_file) selected.old_file = selected.file;
    selected.file = path + '/' + file_id + '_' + event.target.value + '.' + ext;

    attachments[index] = selected;
    setAttachments(attachments);
  };

  const setEdit = (index, value) => {
    const attachments = formState[fsValuesField].attachments;
    const selected = attachments[index];
    selected.edit = value;
    attachments[index] = selected;
    setAttachments(attachments);

    if (setHasPopup) {
      if (value) setHasPopup(true);
      else if (!attachments.some(a => a.edit)) setHasPopup(false);
    }
  };

  const handleSaveChange = (event, index) => {
    const attachments = formState[fsValuesField].attachments;
    const selected = attachments[index];
    if (selected.id) {
      if (!selected.uploaded_by_org_id) selected.uploaded_by_org_id = orgId;
      dispatch(updateWithRoute(ATTACHMENTS, selected.id, selected));
    } else actionOnS3File(ACTION_PUT, selected.old_file, selected.file);
    // TODO: Only delete once update is successful
    delete selected.old_file;
    attachments[index] = selected;
    setAttachments(attachments);
    setEdit(index, false);
  };

  const handleDelete = (event, index) => {
    event.persist();
    const attachments = formState[fsValuesField].attachments;
    const toDelete = attachments[index];

    const delId = toDelete.id;
    const filename = getFilenameWithoutID(toDelete.file);
    const msg =
      intl.formatMessage(
        { id: 'attachment.DELETE_CONFIRMATION' },
        { name: filename }
      ) +
      '\n' +
      intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' });

    const del = window.confirm(msg);
    if (del) {
      attachments.splice(index, 1);
      setAttachments(attachments);

      if (delId)
        dispatch(
          deleteWithRoute(
            ATTACHMENTS,
            delId,
            objectId ? `?object_id=${objectId}` : ''
          )
        );
      // file is not yet saved in db, trigger s3 deletion
      // otherwise, polaris will trigger s3 deletion only if db deletion is successful
      else actionOnS3File(ACTION_DELETE, toDelete.file);
    }
  };

  const { attachments } = formState[fsValuesField];
  const canEdit = isEditable || isRevision;
  const data = attachments || [];
  const uploadPath = getUploadPath(projectId, objectId);

  return (
    <Fragment>
      {download.file ? (
        <Confirm
          message={download.message}
          onClose={() => setDownload({ file: '', message: '' })}
          onConfirm={() => downloadS3File(download.file)}
          setHasPopup={setHasPopup}
          style={{ padding: 0 }}
        />
      ) : null}
      <Card
        className={classNames(
          classes.objectCards,
          hasError && hasError('attachments') ? classes.errorBox : ''
        )}>
        <CardHeader
          className={classNames(classes.grayBackground, classes.padding1)}
          title={
            <FormattedMessage
              defaultMessage="Attachments"
              id={'common.ATTACHMENTS'}
            />
          }
          titleTypographyProps={{
            variant: 'h6'
          }}
        />
        <CardContent className={classes.grayContent}>
          <Grid container spacing={2}>
            <Grid item md={9} xs={9}>
              <Typography variant="h6">
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="h6">
                  <FormattedMessage
                    defaultMessage="Uploaded"
                    id="common.UPLOADED"
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {data.length > 0 ? (
            <Grid container spacing={1}>
              {data.map((row, index) => {
                // get filename without extension
                const file = getFilenameWithoutID(row.file);
                const filename = getFilenameWithoutExt(file);
                const isUploader =
                  !row.uploaded_by_org_id || // this is to handle old objects that do not have this information
                  isCurrentOrg(row.uploaded_by_org_id);

                return (
                  <Fragment key={`attachment_${index}`}>
                    <Grid item md={9} xs={6}>
                      <Box alignItems="center" display="flex">
                        {row.edit ? (
                          <>
                            <TextField
                              className={classes.whiteBackground}
                              inputProps={{
                                'data-testid': `edit_tf_${index}`
                              }}
                              onChange={e => handleChange(e, index)}
                              size="small"
                              style={{ width: 250 }}
                              value={filename}
                            />
                            <IconButton
                              data-testid="save"
                              disabled={!filename}
                              onClick={e => handleSaveChange(e, index)}
                              size="small">
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            {isView ? (
                              <Typography variant="body1">{file}</Typography>
                            ) : (
                              <Button
                                className={classNames(
                                  classes.disableHoverEffect,
                                  classes.attachmentButton
                                )}
                                color="primary"
                                onClick={() => handleAttachmentClick(index)}>
                                {file}
                              </Button>
                            )}
                            {canEdit && isUploader ? (
                              <IconButton
                                data-testid="edit"
                                onClick={e => setEdit(index, true)}
                                size="small">
                                <EditIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Box
                        alignItems="center"
                        className={classes.fullHeight}
                        display="flex"
                        justifyContent="flex-end">
                        {isRevision && isPrevRevFile(row.id) && isUploader ? (
                          <Popup
                            button={
                              <IconButton data-testid="upload" size="small">
                                <UploadIcon fontSize="small" />
                              </IconButton>
                            }
                            setHasPopup={setHasPopup}>
                            <Uploader
                              dropZoneProps={{
                                multiple: false,
                                maxFiles: 1
                              }}
                              nextRank={row.rank}
                              onSubmit={replaceAttachment}
                              uploadPath={uploadPath}
                            />
                          </Popup>
                        ) : null}
                        <IconButton
                          data-testid="download"
                          onClick={() => processDownload(row, setDownload)}
                          size="small">
                          <DownloadIcon fontSize="small" />
                        </IconButton>
                        {canEdit && isUploader ? (
                          !imageSrcList.some(({ src }) => src === row.file) ? (
                            <IconButton
                              data-testid="delete"
                              onClick={e => handleDelete(e, index)}
                              size="small">
                              <DeleteIcon
                                className={classes.delItemIcon}
                                fontSize="small"
                              />
                            </IconButton>
                          ) : (
                            <Tooltip
                              title={
                                <FormattedMessage
                                  id="info.ATTACHMENT_USER_IN_TEXT_FIELD"
                                  defaultMessage="This attachment is used in a text field and cannot be deleted."
                                />
                              }>
                              <IconButton size="small">
                                <Info color="secondary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )
                        ) : null}
                        <Typography variant="body1">
                          {formatDate(row.uploaded, ATTACHMENT_DATE_FORMAT)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          ) : null}
          {canEdit ? (
            <Grid className={classes.paddingTop1} container>
              <Grid item md={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Popup
                    button={
                      <Button color="primary" variant="contained">
                        <FormattedMessage
                          defaultMessage="Add Attachment"
                          id="common.ADD_ATTACHMENT"
                        />
                      </Button>
                    }
                    setHasPopup={setHasPopup}>
                    <Uploader
                      dropZoneProps={{ maxFiles: MAX_FILE_COUNT }}
                      nextRank={data.length + 1}
                      onSubmit={addAttachment}
                      uploadPath={uploadPath}
                    />
                  </Popup>
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </CardContent>
      </Card>
      {hasError && hasError('attachments') ? (
        <FormHelperText className={classes.errorText} error>
          {formState.errors.attachments[0]}
        </FormHelperText>
      ) : null}
    </Fragment>
  );
};

Attachment.propTypes = {
  formState: PropTypes.object,
  handleAttachmentClick: PropTypes.func,
  hasError: PropTypes.func,
  isEditable: PropTypes.bool,
  isView: PropTypes.bool,
  orgId: PropTypes.string,
  projectId: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func
};

Attachment.defaultProps = {
  isView: false
};

export default Attachment;
